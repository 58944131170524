import * as React from 'react';

const pieChartTableLegend = ({ headerTitle, data, description = null, isPercentage = true }) => {
  const itemDisplayValue = (item) => {
    return isPercentage ? `${item.value.toFixed(1)}%` : item.value;
  };

  return (
    <div className="frow frow--content-center col-lg-1-2">
      <table className="styled-table styled-table--alt">
        <tbody>
          {data.map((item) => (
            <tr key={`${headerTitle}-${item.name}`}>
              <td className="text-center">
                <div
                  style={{
                    backgroundColor: item.color,
                    borderRadius: '50%',
                    height: '1.4rem',
                    margin: '0 auto',
                    width: '1.4rem',
                  }}
                />
              </td>
              <td>{item.name}</td>
              <td style={{ textAlign: 'right', fontSize: '1.8rem' }}>{itemDisplayValue(item)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <p className="text-small text-grey-40">{description}</p>
    </div>
  );
};

export default pieChartTableLegend;
