import { Attr, Model } from 'spraypaint';

import { ApplicationRecord } from '../ApplicationRecord';
import { Attachment } from '../Attachment';

@Model()
export class Review extends ApplicationRecord {
  public static jsonapiType = 'portal/reviews';
  @Attr() public endDate: string;
  @Attr() public rating: number;
  @Attr() public reviewableType: string;
  @Attr() public reviewableId: string;
  // Extra Attributes
  @Attr() public liveReviewReports: { id: string; filename: string; path: string; name: string }[];
  @Attr() public bookkeepingAttachmentId: number;
}
