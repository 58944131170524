import React from 'react';
import { format, parse } from 'date-fns';
import ActionButton from './ActionButton';

type HandleTableUpdates = () => void;
const columns = (handleTableUpdates: HandleTableUpdates) => [
  {
    Cell: (row) => <div>{row.original.label}</div>,
    Header: 'Api Key Label',
    accessor: 'label',
    columnName: 'Api Key Label',
    id: 'label',
    sortable: false,
  },
  {
    Cell: (row) => <div>{row.original.apiKey}</div>,
    Header: 'Api Key',
    accessor: 'apiKey',
    columnName: 'Api Key',
    id: 'api_key',
    sortable: false,
  },
  {
    Cell: (row) => {
      const isActive = row.original.active === true;
      const color = isActive ? 'mid-green' : 'mid-orange';
      return (
        <div className="small-uppercase">
          <div className={`status-indicator status-indicator--${color}`}>
            <span className="hide-lg-inline">{isActive ? 'Active' : 'Disabled'}</span>
          </div>
        </div>
      );
    },
    Header: 'Status',
    accessor: 'active',
    columnName: 'Status',
    id: 'active',
    sortable: false,
  },
  {
    Cell: (row) => <div>{format(parse(row.original.createdAt), 'DD MMM YYYY [at] HH:mm')}</div>,
    Header: 'Created At',
    accessor: 'createdAt',
    columnName: 'Created At',
    id: 'created_at',
  },
  {
    Cell: (row) => {
      const disabled = !row.original.active;
      const resource = row.original;
      return (
        <div className="frow">
          <div className="mar-r-1">
            {row.original.createdAt && (
              <ActionButton
                actionType={'disable'}
                apiKeyResource={resource}
                disabled={disabled}
                handleTableUpdates={handleTableUpdates}
              />
            )}
          </div>
          <ActionButton actionType={'delete'} apiKeyResource={resource} handleTableUpdates={handleTableUpdates} />
        </div>
      );
    },
    Header: 'Actions',
    accessor: 'createdAt',
    columnName: 'Actions',
    sortable: false,
  },
  {
    Cell: (row) => <div />,
    Header: '',
    sortable: false,
  },
];

export default columns;
