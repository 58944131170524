import * as React from 'react';

import ReactTooltip from 'react-tooltip';
import startCase from 'lodash.startcase';
import { HeadingThree, SubtitleTwo } from 'components/shared/Typography';
import { grey50 } from 'components/shared/colours';

import DataStatusIcon from 'components/shared/DataSatus/DataStatusIcon';

const portfolioInformation = ({ portfolioObject }) => {
  const { currency, managerName, review_frequency, risk, service_type, dataStatus } = portfolioObject;

  // Justin asked if we could color the text and icons but it looks bad leaving this here in case he wants it anyway
  let themeColor = '#3990A8';
  if (portfolioObject.client_type === 'portfolio') {
    themeColor = '#78AE6E';
  }
  if (portfolioObject.client_type === 'consultancy') {
    themeColor = '#CF7838';
  }

  return (
    <div className="col-md-1-1">
      <div className="platform-content pad-v-2 mar-b-4" style={{ borderBottom: `2px solid ${grey50}` }}>
        <div className="frow frow--gutters frow--justify-between" style={{ width: '100%' }}>
          <div className="mar-r-4">
            <div className="frow frow--nowrap">
              <SubtitleTwo text="Risk Profile" additionalClasses="mar-b-0" />
            </div>
            <HeadingThree text={startCase(risk)} />
          </div>
          <div className="mar-r-4">
            <div className="frow frow--nowrap">
              <SubtitleTwo text="Currency" additionalClasses="mar-b-0" />
            </div>
            <HeadingThree text={currency ? currency.toUpperCase() : 'TBC'} />
          </div>
          <div className="mar-r-4">
            <div>
              <div className="frow frow--nowrap">
                <SubtitleTwo text="Review Frequency" additionalClasses="mar-b-0" />
              </div>
              <HeadingThree text={review_frequency ? startCase(review_frequency) : 'N/A'} />
            </div>
          </div>
          <div className="mar-r-4">
            <div>
              <div className="frow frow--nowrap">
                <SubtitleTwo text="Manager" additionalClasses="mar-b-0" />
              </div>
              <div data-tip={managerName} data-for="portfolio-manager-name">
                <HeadingThree text={managerName ? managerName : 'Unknown'} />
                <ReactTooltip effect="solid" id="portfolio-manager-name" />
              </div>
            </div>
          </div>
          <div className="mar-r-4">
            <div>
              <div className="frow frow--nowrap">
                <SubtitleTwo text="Service" additionalClasses="mar-b-0" />
              </div>
              <HeadingThree text={service_type ? startCase(service_type) : 'Unknown'} />
            </div>
          </div>
          <div>
            <div>
              <div className="frow frow--nowrap">
                <SubtitleTwo text="Data" additionalClasses="mar-b-0" />
              </div>
              <div className="mar-t-1">
                <DataStatusIcon dataStatus={dataStatus} />
                <ReactTooltip effect="solid" id="portfolio-primary-name" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default portfolioInformation;
