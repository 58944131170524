import React, { useState, useEffect } from 'react';
import CustomModal from 'components/shared/CustomModal';
import { ApiKey } from 'javascript/models';

const CreateApiKey = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittable, setIsSubmittable] = useState(false);
  const [apiKeyLabel, setApiKeyLabel] = useState('');
  const [generatedApiKey, setGeneratedApiKey] = useState(null);

  useEffect(() => {
    if (closeModal) {
      setIsSubmitting(false);
      setIsSubmittable(false);
      setGeneratedApiKey(null);
      setApiKeyLabel('');
      setIsOpen(false);
      setCloseModal(false);
    }
  }, [closeModal]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setCloseModal(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setApiKeyLabel(value);

    // Update isSubmittable based on the label's value
    setIsSubmittable(value.trim() !== '');
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      const apiKey = new ApiKey({ label: apiKeyLabel });
      const success = await apiKey.save();
      if (success) {
        const apiKeyCreationEvent = new CustomEvent('apiKey:new');
        document.dispatchEvent(apiKeyCreationEvent);
        setGeneratedApiKey(apiKey.attributes.apiKey);
        setApiKeyLabel('');
        setIsSubmittable(false);
      }
    } catch (e) {}
    setIsSubmitting(false);
  };

  return (
    <div>
      <button className="button button--secondary button--compact" onClick={handleOpen}>
        Create API Key
      </button>
      <CustomModal isOpen={isOpen} handleClose={handleClose} title="Create API Key">
        <div className="modal__content">
          <form action="" className="form form--light" onSubmit={handleSubmit}>
            <div className="frow frow--gutters">
              <div className="col-mc-1-1">
                <label className="form__label">API Key Label</label>
                <input
                  type="text"
                  name="apiKey[label]"
                  onChange={handleChange}
                  value={apiKeyLabel}
                  placeholder="A distinctive string to help you identify the key's usage"
                />
              </div>
              <div className="col-mc-1-1">
                <button
                  className="button button--light-themed button--full-width"
                  disabled={!isSubmittable || isSubmitting}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
          {generatedApiKey && (
            <div>
              <h5 className="mar-b-1 mar-t-1">Generated API Key:</h5>
              <p className="text-small text-black mar-v-0 mar-b-2">{generatedApiKey}</p>
              <p className="text-bold text-small" style={{ color: 'red' }}>
                Make sure to securely save the token, as it cannot be retrieved later on.
              </p>
            </div>
          )}
        </div>
      </CustomModal>
    </div>
  );
};

export default CreateApiKey;
