import React, { useState } from 'react';
import Table from './investmentRestrictions/Table';
import { Review } from 'javascript/models';

const investmentRestrictions = (props) => {
  const { reviewId, reference, reviewEndDate } = props;

  return <Table reviewId={reviewId} reference={reference} endDate={reviewEndDate} />;
};

export default investmentRestrictions;
