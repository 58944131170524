import React from 'react';

const bookkeepingIconStyles = {
  justifyContent: 'center',
  alignContent: 'center',
  borderWidth: '2px',
  borderRadius: '50px',
  borderStyle: 'solid',
};

const DataStatusIcon = ({ dataStatus, size = 44 }) => {
  const heightAndWidth = { height: `${size}px`, width: `${size}px` };

  switch (dataStatus) {
    // COMMENTED OUT FOR SILENT RELEASE
    // case 'bookkeeping_enabled':
    //   return (
    //     <div data-tip="Holdings level data with the Bookkeeping service enabled">
    //       <div className="frow" style={{ ...bookkeepingIconStyles, ...heightAndWidth, borderColor: '#f1af09' }}>
    //         <i className="fas fa-2x text-brand-gold fa-medal" style={{ marginTop: 2 }} />
    //       </div>
    //     </div>
    //   );
    // case 'bookkeeping_potential':
    //   return (
    //     <div data-tip="Holdings level data with the potential to enable the Bookkeeping service">
    //       <div className="frow" style={{ ...bookkeepingIconStyles, ...heightAndWidth, borderColor: 'grey' }}>
    //         <i className="fas fa-2x text-brand-gold fa-medal" style={{ marginTop: 2 }} />
    //       </div>
    //     </div>
    //   );
    case 'enriched_holdings':
      return (
        // COMMENTED OUT FOR SILENT RELEASE
        // <div data-tip="Holdings level data but without the ability to enable the Bookkeeping service">
        <div data-tip="Holdings level data">
          <i className="fas fa-2x text-brand-gold fa-medal" />
        </div>
      );
    case 'no_data':
      return (
        <div data-tip="Does not have holdings level data">
          <i className="fas fa-2x text-brand-light-grey fa-medal" />
        </div>
      );
    default:
      return <div data-tip="There is no review for this period">N/A</div>;
  }
};

export default DataStatusIcon;
