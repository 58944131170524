import React, { useContext, useState, useEffect, useRef } from 'react';

import ReactPieChart from 'components/shared/charting/recharts/ReactPieChart';
import useFlag from './Flags/useFlag';

import { default as AssetSlider } from '../AssetAllocationSlider';

import { useDefaultTheme } from 'components/shared/customHooks/useThemeColours';

import { Frow } from 'components/frow';
import Checkbox from '../Checkbox';
import { InvestmentRestrictionsContext } from './context';
import { Mandate } from 'javascript/models';

export interface IStrategicAllocationProps {
  namePrefix: string;
  mandate: Mandate;
  iRPrefix: string;
}

const StrategicAllocation = (props: IStrategicAllocationProps) => {
  const { cashAndFixedIncomeLocked, setCashAndFixedIncomeLocked, compliance } =
    useContext(InvestmentRestrictionsContext);
  const { namePrefix, iRPrefix } = props;

  const [cashStrategic, setStrategicCash] = useFlag(compliance, 'cash_strategic');
  const [fixedIncomeStrategic, setStrategicFixedIncome] = useFlag(compliance, 'fixed_income_strategic');
  const [equityStrategic, setStrategicEquity] = useFlag(compliance, 'equity_strategic');
  const [hedgeFundStrategic, setStrategicHedgeFund] = useFlag(compliance, 'hedge_fund_strategic');
  const [realEstateStrategic, setStrategicRealEstate] = useFlag(compliance, 'real_estate_strategic');
  const [commoditiesStrategic, setStrategicCommodities] = useFlag(compliance, 'commodities_strategic');
  const [privateEquityStrategic, setStrategicPrivateEquity] = useFlag(compliance, 'private_equity_strategic');
  const [multiAssetStrategic, setStrategicMultiAsset] = useFlag(compliance, 'multi_asset_strategic');
  const [otherStrategic, setStrategicOther] = useFlag(compliance, 'other_strategic');

  const renderRef = useRef(false);

  const resetCashAndFixedIncome = () => {
    setStrategicCash({ ...cashStrategic, value: 0 });
    setStrategicFixedIncome({ ...fixedIncomeStrategic, value: 0 });
  };

  useEffect(() => {
    // Only reset the min and max values if this is not the first render
    if (renderRef.current) {
      resetCashAndFixedIncome();
    }
  }, [cashAndFixedIncomeLocked]);

  useEffect(() => {
    // Check if this is the first render
    renderRef.current = true;
  }, []);

  const flags = [
    cashStrategic,
    fixedIncomeStrategic,
    equityStrategic,
    hedgeFundStrategic,
    otherStrategic,
    realEstateStrategic,
    commoditiesStrategic,
    privateEquityStrategic,
    multiAssetStrategic,
  ];

  if (flags.some((flag) => !flag)) return null;

  const totalAssetValue = flags.reduce((sum, flag) => sum + flag.value, 0);

  const unassignedValue = 100 - totalAssetValue;

  const getColour = useDefaultTheme('assetClass');

  const cashAndFixedIncomePieChartValues = () => {
    if (cashAndFixedIncomeLocked) {
      return [{ name: 'Cash & Fixed Income', value: cashStrategic.value, color: getColour('Cash') }];
    } else {
      return [
        { name: 'Cash', value: cashStrategic.value, color: getColour('Cash') },
        { name: 'Fixed Income', value: fixedIncomeStrategic.value, color: getColour('Fixed Income') },
      ];
    }
  };

  const pieChartData = [
    ...cashAndFixedIncomePieChartValues(),
    { name: 'Equity', value: equityStrategic.value, color: getColour('Equity') },
    { name: 'Hedge Fund', value: hedgeFundStrategic.value, color: getColour('Hedge Fund') },
    { name: 'Real Estate', value: realEstateStrategic.value, color: getColour('Real Estate') },
    { name: 'Commodities', value: commoditiesStrategic.value, color: getColour('Commodities') },
    { name: 'Private Equity', value: privateEquityStrategic.value, color: getColour('Private Equity') },
    { name: 'Multi Asset', value: multiAssetStrategic.value, color: getColour('Multi Asset') },
    { name: 'Other', value: otherStrategic.value, color: getColour('Other') },
    { name: 'Unassigned', value: unassignedValue < 1 ? 0 : unassignedValue, color: '#ffffff' },
  ];

  const handleCashAndFixedIncomeLocked = (value) => {
    setCashAndFixedIncomeLocked(value);
    resetCashAndFixedIncome();
  };

  return (
    <fieldset className="form__fieldset">
      <h4 className="form__fieldset-label">Strategic Allocations</h4>
      <Frow className="mar-b-3">
        <Checkbox
          label="Combine Cash and Fixed Income"
          onToggle={handleCashAndFixedIncomeLocked}
          checked={cashAndFixedIncomeLocked}
          param={`${iRPrefix}[cash_and_fixed_income_locked]`}
        />
      </Frow>
      <div className="frow frow--gutters">
        <div className="col-md-2-5">
          <Frow gutterSize={1}>
            <AssetSlider
              availableSpace={unassignedValue}
              setAllocation={(value) => setStrategicCash({ ...cashStrategic, value: value })}
              color={getColour('cash')}
              label={cashAndFixedIncomeLocked ? 'Cash & Fixed Income' : 'Cash'}
              flag={cashStrategic}
              namePrefix={namePrefix}
            />

            {!cashAndFixedIncomeLocked && (
              <>
                <AssetSlider
                  availableSpace={unassignedValue}
                  setAllocation={(value) => setStrategicFixedIncome({ ...fixedIncomeStrategic, value: value })}
                  color={getColour('Fixed Income')}
                  label={'Fixed Income'}
                  flag={fixedIncomeStrategic}
                  namePrefix={namePrefix}
                />
              </>
            )}

            <AssetSlider
              availableSpace={unassignedValue}
              setAllocation={(value) => setStrategicEquity({ ...equityStrategic, value: value })}
              color={getColour('Equity')}
              label={'Equity'}
              flag={equityStrategic}
              namePrefix={namePrefix}
            />

            <AssetSlider
              availableSpace={unassignedValue}
              setAllocation={(value) => setStrategicHedgeFund({ ...hedgeFundStrategic, value: value })}
              color={getColour('Hedge Fund')}
              label={'Hedge Fund'}
              flag={hedgeFundStrategic}
              namePrefix={namePrefix}
            />

            <AssetSlider
              availableSpace={unassignedValue}
              setAllocation={(value) => setStrategicRealEstate({ ...realEstateStrategic, value: value })}
              color={getColour('Real Estate')}
              label={'Real Estate'}
              flag={realEstateStrategic}
              namePrefix={namePrefix}
            />

            <AssetSlider
              availableSpace={unassignedValue}
              setAllocation={(value) => setStrategicCommodities({ ...commoditiesStrategic, value: value })}
              color={getColour('Commodities')}
              label={'Commodities'}
              flag={commoditiesStrategic}
              namePrefix={namePrefix}
            />

            <AssetSlider
              availableSpace={unassignedValue}
              setAllocation={(value) => setStrategicPrivateEquity({ ...privateEquityStrategic, value: value })}
              color={getColour('Private Equity')}
              label={'Private Equity'}
              flag={privateEquityStrategic}
              namePrefix={namePrefix}
            />

            <AssetSlider
              availableSpace={unassignedValue}
              setAllocation={(value) => setStrategicMultiAsset({ ...multiAssetStrategic, value: value })}
              color={getColour('Multi Asset')}
              label={'Multi Asset'}
              flag={multiAssetStrategic}
              namePrefix={namePrefix}
            />

            <AssetSlider
              availableSpace={unassignedValue}
              setAllocation={(value) => setStrategicOther({ ...otherStrategic, value: value })}
              color={getColour('Other')}
              label={'Other'}
              flag={otherStrategic}
              namePrefix={namePrefix}
            />
          </Frow>
        </div>
        <div className="col-sm-3-5">
          <ReactPieChart animationDuration={500} data={pieChartData} height={300} labelText="" displayLegend />
        </div>
      </div>
    </fieldset>
  );
};

export default StrategicAllocation;
