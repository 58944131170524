import * as React from 'react';

import ClientTable from '../clients/Table';
import IraTable from '../investmentRiskAssessments/Table';
import Table from '../portfolios/Table';
import UserTable from '../users/Table';
import ClientRoles from './roles/ClientRoles';
import CurrentUserContext from 'components/shared/context/CurrentUserContext';
import { Administrator, User } from 'javascript/models';

import { newPlatformClientInvestmentRiskAssessmentPath } from 'javascript/application/ts_routes';

type TPermissions = {
  [key: string]: { description: string; value: number };
};

export type TPermissionGroups = {
  [key: string]: TPermissions;
};

interface IProps {
  clientId: string;
  currentUserId: string;
  clientTypes: string[];
  currencies: string[];
  isExportable: boolean;
  newUserPath: string;
  riskOptions: string[];
  availablePerms: TPermissionGroups;
  statuses: string[];
  tierCategories: string[];
  tierTypes: string[];
}

export default function show(props: IProps) {
  const [currentUser, setCurrentUser] = React.useState<Administrator>();

  const {
    clientId,
    currentUserId,
    clientTypes,
    currencies,
    isExportable,
    newUserPath,
    riskOptions,
    availablePerms,
    statuses,
    tierCategories,
    tierTypes,
  } = props;
  const [selectedTab, setSelectedTab] = React.useState<'users' | 'portfolios' | 'mirs' | 'clients' | 'roles'>(
    'portfolios',
  );

  const getUser = async () => {
    const { data } = await Administrator.find(currentUserId);
    setCurrentUser(data);
  };

  React.useEffect(() => {
    getUser();
  }, []);

  const tabMapper = {
    users: <UserTable scope={User.where({ client_id: clientId })} newPath={newUserPath} />,
    clients: (
      <ClientTable
        {...{ clientTypes, tierCategories, tierTypes }}
        isExportable={false}
        scope={{ parent_client_id: clientId }}
        hasHeader={false}
        title=""
      />
    ),
    mirs: (
      <IraTable
        scope={{ assessable_type: 'Client', assessable_id: clientId }}
        newPath={newPlatformClientInvestmentRiskAssessmentPath(clientId)}
      />
    ),
    portfolios: (
      <Table {...{ currencies, clientTypes, isExportable, riskOptions, statuses }} scope={{ client_id: clientId }} />
    ),
    roles: <ClientRoles {...{ clientId: +clientId }} />,
  };
  const renderTable = (): JSX.Element => tabMapper[selectedTab];

  function handleTabSelect() {
    setSelectedTab(this);
  }

  const classes = 'tabbed-nav__tab col-flex-grow-1 tabbed-nav__tab--right-bordered';

  const tabs = ['users', 'portfolios', 'clients', 'mirs', 'roles'];
  const tab = (tabType: string) => (
    <div
      className={`${classes} ${selectedTab === tabType ? 'tabbed-nav__tab--active' : ''}`}
      onClick={handleTabSelect.bind(tabType)}
    >
      <span className="text-bold letter-spacing-1">{tabType}</span>
    </div>
  );

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <div className="platform-panel">
        <div className="tabbed-nav">{tabs.map((tabType) => tab(tabType))}</div>
        <div className="platform-content platform-content--padding-all">{renderTable()}</div>
      </div>
    </CurrentUserContext.Provider>
  );
}
