import * as React from 'react';

import lodashStartcase from 'lodash.startcase';

import FormRow from 'components/shared/forms/FormRow';
import ReactSelect from 'components/shared/forms/ReactSelect';
import ISelectOption from 'components/interfaces/ISelectOption';

interface IProps {
  adminOptions: ISelectOption[];
  client: any;
  clientType: string;
  currencyOptions: ISelectOption[];
  clientTypes: ISelectOption[];
  jurisdictionOptions: ISelectOption[];
  regionOptions: ISelectOption[];
  rmAdminOptions: ISelectOption[];
  setSelectedClientType(selectedOption: string): void;
  setTierCategory(selectedOption: string): void;
  setTierType(selectedOption: string): void;
  tierCategory: string;
  tierCategories: ISelectOption[];
  tierType: string;
  tierTypes: ISelectOption[];
}

const DEACTIVATION_REASONS = [
  'Lost to a competitor',
  'Did not onboard',
  'Merged with another client',
  'Created in error',
  'Other',
];

const Information = (props: IProps) => {
  const {
    adminOptions,
    client,
    clientType,
    currencyOptions,
    clientTypes,
    jurisdictionOptions,
    regionOptions,
    rmAdminOptions,
    setSelectedClientType,
    setTierCategory,
    setTierType,
    tierCategory,
    tierCategories,
    tierType,
    tierTypes,
  } = props;

  const {
    autosendReviews,
    consolidatedFeeTier1,
    bookkeepingFee,
    consolidatedFeeTier3,
    emapFee,
    combinedFee,
    combinedComponentFee,
    feeCurrency,
    jurisdiction,
    reference,
    region,
    name,
    quantOnlyFee,
    advisoryRelationshipManagerId,
    monitoringRelationshipManagerId,
    active,
    deactivationReason,
  } = client;

  const deatcivationReasons = DEACTIVATION_REASONS.map((reason) => {
    return { label: reason, value: reason };
  });

  const defaultDeactivationReason = deatcivationReasons.find((reason) => reason.value === deactivationReason);
  const defaultMonitoringRM = rmAdminOptions.find((admin) => admin.value === monitoringRelationshipManagerId);
  const defaultAdvisoryRM = adminOptions.find((admin) => admin.value === advisoryRelationshipManagerId);
  const defaultCurrency = currencyOptions.find((currency) => currency.value === feeCurrency);
  const defaultJurisdiction = jurisdictionOptions.find((jOption) => jOption.value === jurisdiction);
  const defaultRegion = regionOptions.find((rOption) => rOption.value === region);
  const [clientActive, setClientActive] = React.useState(active);

  function handleClientTypeChange(selectedOption) {
    setSelectedClientType(selectedOption.value);
  }

  function handleTierCategoryChange(selectedOption) {
    setTierCategory(selectedOption.value);
  }

  function handleTierTypeChange(selectedOption) {
    setTierType(selectedOption.value);
  }

  const FeeSettings = () => {
    if (clientType !== 'monitoring' && clientType !== 'mixed') return;

    return (
      <React.Fragment>
        <FormRow note="">
          <label htmlFor="name" className="form__label">
            EMAP Fee
          </label>
          <input type="number" name="client[emap_fee]" defaultValue={emapFee} step={0.01} />
        </FormRow>
        <FormRow note="">
          <label htmlFor="name" className="form__label">
            Consolidated Fee Tier 1
          </label>
          <input type="number" name="client[consolidated_fee_tier_1]" defaultValue={consolidatedFeeTier1} step={0.01} />
        </FormRow>
        <FormRow note="">
          <label htmlFor="name" className="form__label">
            Bookkeeping Fee
          </label>
          <input type="number" name="client[bookkeeping_fee]" defaultValue={bookkeepingFee} step={0.01} />
        </FormRow>
        <FormRow note="">
          <label htmlFor="name" className="form__label">
            Consolidated Fee Tier 3
          </label>
          <input type="number" name="client[consolidated_fee_tier_3]" defaultValue={consolidatedFeeTier3} step={0.01} />
        </FormRow>
        <FormRow note="">
          <label htmlFor="name" className="form__label">
            Quant Only fee
          </label>
          <input type="number" name="client[quant_only_fee]" defaultValue={quantOnlyFee} step={0.01} />
        </FormRow>

        <FormRow note="">
          <label htmlFor="name" className="form__label">
            Combined fee
          </label>
          <input type="number" name="client[combined_fee]" defaultValue={combinedFee} step={0.01} />
        </FormRow>
        <FormRow note="">
          <label htmlFor="name" className="form__label">
            Combined Component fee
          </label>
          <input type="number" name="client[combined_component_fee]" defaultValue={combinedComponentFee} step={0.01} />
        </FormRow>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <h4 className="form__fieldset-label">Information</h4>
      <div className="frow frow--gutters">
        <FormRow note="">
          <div className="pretty p-icon">
            <input type="hidden" name="client[active]" value={clientActive} />
            <input
              type="checkbox"
              value={clientActive ? '1' : '0'}
              checked={clientActive}
              onChange={() => setClientActive((prevActive) => !prevActive)}
            />
            <div className="state p-blue">
              <i className="icon-tick icon icon-push-down-small" />
              <label className="text-white">Active</label>
            </div>
          </div>
        </FormRow>

        {!clientActive && (
          <FormRow note="">
            <label htmlFor="deactivation_reason" className="form__label">
              Deactivation Reason
            </label>
            <ReactSelect
              theme="dark"
              name="client[deactivation_reason]"
              id="deactivation_reason"
              options={deatcivationReasons}
              defaultValue={defaultDeactivationReason}
            />
          </FormRow>
        )}

        <FormRow note="">
          <label htmlFor="name" className="form__label">
            Name
          </label>
          <input type="text" name="client[name]" defaultValue={name} />
        </FormRow>
        <FormRow note="">
          <label htmlFor="name" className="form__label">
            Reference
          </label>
          <input type="text" name="client[reference]" defaultValue={reference} />
        </FormRow>
        <FormRow note="">
          <label htmlFor="name" className="form__label">
            Monitoring Relationship Manager
          </label>
          <ReactSelect
            theme="dark"
            name="client[monitoring_relationship_manager_id]"
            id="monitoring_relationship_manager_id"
            options={rmAdminOptions}
            defaultValue={defaultMonitoringRM}
          />
        </FormRow>
        <FormRow note="">
          <label htmlFor="name" className="form__label">
            Advisory Relationship Manager
          </label>
          <ReactSelect
            theme="dark"
            name="client[advisory_relationship_manager_id]"
            id="advisory_relationship_manager_id"
            options={rmAdminOptions}
            defaultValue={defaultAdvisoryRM}
          />
        </FormRow>
        <FormRow note="">
          <label htmlFor="name" className="form__label">
            Client Type
          </label>
          <ReactSelect
            theme="dark"
            name="client[client_type]"
            id="client_type"
            options={clientTypes}
            value={{ label: lodashStartcase(clientType), value: clientType }}
            handleChange={handleClientTypeChange}
          />
        </FormRow>

        <FormRow note="">
          <label htmlFor="name" className="form__label">
            Tier Category
          </label>
          <ReactSelect
            theme="dark"
            name="client[tier_category]"
            id="tier_category"
            options={tierCategories}
            value={{ label: lodashStartcase(tierCategory), value: tierCategory }}
            handleChange={handleTierCategoryChange}
          />
        </FormRow>

        <FormRow note="">
          <label htmlFor="name" className="form__label">
            Tier Type
          </label>
          <ReactSelect
            theme="dark"
            name="client[tier_type]"
            id="tier_type"
            options={tierTypes}
            value={{ label: lodashStartcase(tierType), value: tierType }}
            handleChange={handleTierTypeChange}
          />
        </FormRow>

        {FeeSettings()}
        <FormRow note="">
          <label htmlFor="name" className="form__label">
            Fee Currency
          </label>
          <ReactSelect
            theme="dark"
            name="client[fee_currency]"
            id="fee_currency"
            options={currencyOptions}
            defaultValue={defaultCurrency}
          />
        </FormRow>
        <FormRow note="">
          <label htmlFor="name" className="form__label">
            Jurisdiction
          </label>
          <ReactSelect
            theme="dark"
            name="client[jurisdiction]"
            id="jurisdiction"
            options={jurisdictionOptions}
            defaultValue={defaultJurisdiction}
          />
        </FormRow>
        <FormRow note="">
          <label htmlFor="name" className="form__label">
            Region
          </label>
          <ReactSelect
            theme="dark"
            name="client[region]"
            id="region"
            options={regionOptions}
            defaultValue={defaultRegion}
          />
        </FormRow>
        <FormRow
          className="col-mc-1-1"
          note="If this is ticked when reviews are approved for this clients portfolios the reports will automatically
          emailed to everyone marked as an EMAP user for that portfolio."
        >
          <label htmlFor="name" className="form__label">
            Autosend Reviews
          </label>
          <input type="hidden" name="client[autosend_reviews]" value="0" />
          <div className="pretty p-icon">
            <input
              type="checkbox"
              id="autosend_reviews"
              name="client[autosend_reviews]"
              defaultChecked={autosendReviews}
            />
            <div className="state p-blue">
              <i className="icon-tick icon icon-push-down-small" />
              <label className="text-white" htmlFor="autosend_reviews">
                Automatically email completed reviews
              </label>
            </div>
          </div>
        </FormRow>
      </div>
    </React.Fragment>
  );
};

export default Information;
