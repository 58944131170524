import * as React from 'react';

import { snakeCase, startCase } from 'lodash';
import { format } from 'date-fns';

import Loading from '../../../../shared/Loading';
import { Portfolio } from 'javascript/models/portal/Portfolio';
import ReactPieChart from '../../../../shared/charting/recharts/ReactPieChart';
import PieChartTableLegend from '../PieChartTableLegend';
import useChartFilter from '../../../../shared/customHooks/useChartFilter';
import stateToggle from '../shared/stateToggle';
import { useClientPortalTheme } from 'components/shared/colours/themes/clientPortal';
import ClientPortalCard from 'components/portal/shared/ClientPortalCard';

const ServiceType = ({ clientId, selectedDate, filterState }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [meta, setMeta] = React.useState();
  const [data, setData] = React.useState([]);
  const [selectedSegment, setSelectedSegment] = filterState;

  React.useEffect(() => {
    setIsLoading(true);
    getPortfolios().then(() => setIsLoading(false));
  }, [selectedDate]);

  const handleSelect = React.useCallback(
    (slice) => {
      stateToggle(setSelectedSegment, false)(snakeCase(slice));
    },
    [selectedSegment],
  );

  useChartFilter(data, setData, 'name', selectedSegment);

  const colourGen = useClientPortalTheme('serviceType');

  async function getPortfolios() {
    const formattedDate = format(selectedDate, 'YYYY-MM-DD');

    const scope = Portfolio.per(0)
      .where({ status: 'live', client_id: clientId, review_date: formattedDate })
      .extraParams({ selected_date: formattedDate })
      .stats({ count: 'total', breakdown: 'service_type' });
    const { meta } = await scope.all();

    const { service_type } = meta.stats.breakdown;
    const dataArr = service_type.map((type) => {
      type.color = colourGen(type.name);
      type.name = startCase(type.name);
      return type;
    });

    setData(dataArr);
    setMeta(meta);
  }

  function renderChart() {
    const count = meta.stats.count.total;

    const legendProps = {
      headerTitle: 'Service Type',
      description: 'Split of portfolios by service type with a review in the selected quarter.',
      data,
    };

    return (
      <ClientPortalCard title="Service Type">
        <div className="frow">
          <div className="col-lg-1-2">
            <ReactPieChart
              activeShape
              animationBegin={50}
              animationDuration={400}
              data={data}
              height={320}
              labelText={count}
              noData={count < 1}
              displayLegend={false}
              setSelectedSlice={handleSelect}
            />
          </div>
          <PieChartTableLegend {...legendProps} />
        </div>
      </ClientPortalCard>
    );
  }

  return isLoading ? <Loading /> : renderChart();
};

export default ServiceType;
