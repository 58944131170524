import React, { useMemo, useState, useContext, useEffect } from 'react';
import { InvestmentRestrictionsContext } from '../context';
import { uniqueId } from 'lodash';

import { startCase, snakeCase } from 'lodash';
import { applyTo, objOf, map, mergeAll } from 'ramda';
import Checkbox from '../../Checkbox/index';

import { Flag, SecurityClassification } from 'javascript/models';

import RangeSlider from 'components/shared/forms/RangeSlider';
import ReactSelect from 'components/shared/forms/ReactSelect';
import { CriterionOption } from './FlagsSelector';

export interface IFlagSelectorProps {
  backgroundColorGenerator: (key: string) => string;
  criterionOptions: CriterionOption[];
  flag: Flag;
  flagType: string;
  index: number;
  namePrefix: string;
  showSlider?: boolean;
  showAggregate?: boolean;
  inheritFromClient?: boolean;
}

const FlagSelector = (props: IFlagSelectorProps) => {
  const {
    backgroundColorGenerator,
    criterionOptions,
    flag,
    flagType,
    index,
    namePrefix,
    showSlider = true,
    showAggregate = true,
    inheritFromClient,
  } = props;
  const { compliance } = useContext(InvestmentRestrictionsContext);
  const [isDeleted, setIsDeleted] = useState(false);
  const [aggregateValue, setAggregateValue] = useState(flag.aggregate);
  const [enabled, setEnabled] = useState(flag.enabled);
  const [generateActionPoint, setGenerateActionPoint] = useState(flag.generateActionPoint);
  const [selectedCriterion, setSelectedCriterion] = useState<CriterionOption>(null);

  const [min, setMin] = useState(flag.minimum);
  const [max, setMax] = useState(flag.maximum);

  const name = `${namePrefix}[${flag.id || uniqueId()}]`;

  const findCriterionOption = (criterionId: string) => {
    return criterionOptions.find((option) => option.value === criterionId);
  };

  useEffect(() => {
    if (flag.criterionId) {
      setSelectedCriterion(findCriterionOption(flag.criterionId));
    }
  }, [flag]);

  const handleCriterionChange = (selection) => {
    setSelectedCriterion(selection);
  };

  const handleDelete = () => {
    setIsDeleted(true);
  };

  const hiddenDeleteField = <input type="hidden" value="true" name={`${name}[_destroy]`} />;
  const criterionHiddenField = selectedCriterion && selectedCriterion.value && (
    <input type="hidden" value={selectedCriterion.value} name={`${name}[criterion_id]`} />
  );

  const deleteButton = (
    <div
      className="button button--secondary button--danger button--icon"
      onClick={handleDelete}
      style={{ margin: '6px 0' }}
    >
      <i className="icon-bin icon-fw" />
    </div>
  );

  const handleSlideEnd = ([newMin, newMax]) => {
    setMin(newMin);
    setMax(newMax);
  };

  const defaultValue = [min, max];
  const pushable = 0;

  const hiddenIdField = <input type="hidden" name={`${name}[id]`} value={flag.id} />;

  return (
    <div className={`mar-v-2 pad-v-2 pad-h-2 ${isDeleted && 'hidden'}`} style={{ backgroundColor: '#3c3c3c' }}>
      <div className="frow frow--gutters mar-b-1">
        <div className="col-md-2-5">
          <div className="frow frow--items-center">
            <div className="col-md-5-5">
              <label htmlFor="" className="form__label">
                Criterion
              </label>
            </div>
            <div className="col-md-5-5">
              <ReactSelect
                theme="dark"
                name={`${name}[criterion_id]`}
                id="criterion"
                options={criterionOptions}
                value={selectedCriterion || null}
                handleChange={handleCriterionChange}
              />
            </div>
          </div>
        </div>
        <div className="frow col-md-3-5 frow--row-center frow--items-center">
          <div className="mar-b-3 col-md-9-12 mar-l-3">
            {showSlider && (
              <RangeSlider
                {...{
                  // backgroundColor,
                  defaultValue,
                  handleSlideEnd,
                  pushable,
                }}
                showValues
              />
            )}
          </div>
          <div className="col-md-1-12 pad-l-5">{deleteButton}</div>
        </div>
        {flag.id && hiddenIdField}
        {selectedCriterion && criterionHiddenField}
        <input type="hidden" name={`${name}[flag_type]`} value={flagType} />
        <input type="hidden" name={`${name}[maximum]`} value={max} />
        <input type="hidden" name={`${name}[minimum]`} value={min} />
        {isDeleted && hiddenDeleteField}

        <div className="frow">
          {showAggregate && (
            <div className="pretty p-icon">
              <Checkbox label="Aggregate across type" onToggle={setAggregateValue} checked={aggregateValue} />
              <input type="hidden" name={`${name}[aggregate]`} value={String(aggregateValue)} />
            </div>
          )}

          <div className="pretty p-icon">
            <Checkbox label="Enabled" onToggle={setEnabled} checked={enabled} />
            <input type="hidden" name={`${name}[enabled]`} value={String(enabled)} />
          </div>

          <div className="pretty p-icon">
            <Checkbox label="Generate AP's" onToggle={setGenerateActionPoint} checked={generateActionPoint} />
            <input type="hidden" name={`${name}[generate_action_point]`} value={String(generateActionPoint)} />
          </div>

          <input type="hidden" name={`${name}[inherit_from_client]`} value={String(inheritFromClient)} />
        </div>
      </div>
    </div>
  );
};

export default FlagSelector;
