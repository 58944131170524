import { Attr, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class Attachment extends ApplicationRecord {
  public static jsonapiType = 'attachments';

  @Attr() public attachableId: number;
  @Attr() public attachableType: string;
  @Attr() public createdAt: Date;
  @Attr() public updatedAt: Date;
}
