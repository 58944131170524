import React, { useState } from 'react';
import * as $ from 'jquery';
import { Spinner } from '@blueprintjs/core';

import ReactSelect from 'components/shared/forms/ReactSelect';
import { Client } from 'javascript/models/Client';
import { createAlert } from 'components/shared/Utils';
import { set } from 'lodash';

interface ISelectOptions {
  label: string;
  value: string;
}

interface IProps {
  client: Client & { relationship_manager: string };
  clientCurrency: string;
  currencies: ISelectOptions[];
  selectableQuarters: { display: string; value: string }[];
  selectableUsers: ISelectOptions[];
  afterSubmit: () => void;
  defaultReviewDate: string;
}

export default function MirDownload(props: IProps) {
  const { client, clientCurrency, currencies, selectableQuarters, selectableUsers, afterSubmit, defaultReviewDate } =
    props;
  const defaultSelectableReviewDate = selectableQuarters.find((quarter) => quarter.value === defaultReviewDate);

  const [includeAll, setIncludeAll] = useState(true);
  const [userIds, setUserIds] = useState([]);
  const [currency, setCurrency] = useState(clientCurrency);
  const [reviewDate, setReviewDate] = useState<{ label: string; value: string }>({
    label: defaultSelectableReviewDate.display,
    value: defaultSelectableReviewDate.value,
  });
  const [fileType, setFileType] = useState('pdf');
  const [errors, setErrors] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const data = {
    investment_risk_assessment: {
      client_id: client.id,
      include_all: includeAll,
      user_ids: userIds,
      currency,
      review_date: reviewDate.value,
      file_type: fileType,
    },
  };

  function handleSubmit(event) {
    event.preventDefault();
    setErrors([]);
    setIsSubmitting(true);

    $.ajax({
      data: data,
      dataType: 'json',
      type: 'post',
      url: '/portal/downloads',
      error: (response) => {
        const jsonRepsonse = response.responseJSON;
        if (jsonRepsonse && jsonRepsonse.errors) {
          setErrors([...jsonRepsonse.errors]);
        }
      },
      success: () => {
        createAlert('success', 'Your MIR is being created you will receive a notification when it has completed', 1500);
        afterSubmit();
      },
      complete: () => {
        setIsSubmitting(false);
      },
    });
  }

  const formattedSelectableQuarters = selectableQuarters.map((quarter) => {
    return { label: quarter.display, value: quarter.value };
  });

  function toggleIncludeAll() {
    setIncludeAll(!includeAll);
  }

  const documentOptions = [
    { value: 'pdf', label: 'PDF' },
    { value: 'excel', label: 'Excel' },
    { value: 'both', label: 'PDF & Excel' },
  ];

  const formatOptions = documentOptions.map((option, index) => {
    return (
      <div className="col-sm-1-2" key={option.value}>
        <div className="pretty pretty--large pretty--with-label p-round">
          <input
            type="radio"
            value={option.value}
            name="investment_risk_assessment[file_type]"
            id="series"
            defaultChecked={option.value === 'pdf'}
            onChange={(event) => setFileType(event.target.value)}
            disabled={isSubmitting}
          />
          <div className="state p-blue-circle">
            <label className="text-white">{option.label}</label>
          </div>
        </div>
      </div>
    );
  });

  const userSelect = (
    <div className="col-mc-1-1">
      <label htmlFor="primary_user" className="form__label">
        <span>Only portfolios belonging to the selected users will be included</span>
      </label>
      <ReactSelect
        theme="dark"
        name="investment_risk_assessment[user_ids][]"
        id="user_ids"
        isMulti
        options={selectableUsers}
        onChange={(selectedOptions) => setUserIds(selectedOptions.map((option) => option.value))}
        value={selectableUsers.filter((option) => userIds.includes(option.value)) || []}
        isDisabled={isSubmitting}
      />
    </div>
  );

  const renderErrors = () => {
    if (errors.length > 0) {
      return (
        <div className="col-mc-1-1">
          <ul>
            {errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
      );
    }
  };

  return (
    <div className="frow frow--gutters">
      {renderErrors()}

      <div className="col-mc-1-1">
        <label htmlFor="" className="form__label">
          Do you want to include all portfolios?
        </label>
        <div className="frow">
          <div className="pretty p-icon">
            <input
              type="checkbox"
              id="include_all"
              onChange={toggleIncludeAll}
              checked={includeAll}
              name="investment_risk_assessment[include_all]"
              value="1"
              disabled={isSubmitting}
            />
            <div className="state p-blue">
              <i className="icon-tick icon icon-push-down-small" />
              <label className="text-white">Include all</label>
            </div>
          </div>
        </div>
      </div>

      {!includeAll && userSelect}

      <div className="col-mc-1-1">
        <label htmlFor="" className="form__label">
          Format
        </label>
        <div className="frow frow--gutters">{formatOptions}</div>
      </div>
      <div className="col-mc-1-1">
        <label htmlFor="" className="form__label">
          Currency
        </label>
        <ReactSelect
          theme="dark"
          name="investment_risk_assessment[currency]"
          id="review_date"
          options={currencies}
          defaultValue={{ value: clientCurrency, label: clientCurrency.toUpperCase() }}
          onChange={(selectedOption) => setCurrency(selectedOption.value)}
          value={{ value: currency, label: currency.toUpperCase() }}
          isDisabled={isSubmitting}
        />
      </div>
      <div className="col-mc-1-1">
        <label htmlFor="" className="form__label">
          Review Date
        </label>
        <ReactSelect
          theme="dark"
          name="investment_risk_assessment[review_date]"
          id="review_date"
          options={formattedSelectableQuarters}
          onChange={(selectedOption) => setReviewDate(selectedOption)}
          value={reviewDate}
          isDisabled={isSubmitting}
        />
      </div>

      <div className="col-mc-1-1">
        <button
          className="button button--primary button--full-width button--no-min-width"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? <Spinner size={Spinner.SIZE_SMALL} /> : 'Generate MIR'}
        </button>
      </div>
    </div>
  );
}
