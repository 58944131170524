import React from 'react';

import { useDefaultTheme } from 'components/shared/colours';
import Loading from 'components/shared/Loading';
import Checkbox from '../../Checkbox/index';

import { Frow } from 'components/frow';

import { Flag, SecurityClassification } from 'javascript/models';

import FlagSelector from './FlagSelector';
import { set } from 'lodash';

interface IFlagsSelectorProps {
  flags: Flag[];
  namePrefix: string;
  flagType: string;
  addFlag: () => void;
  classificationType: string;
  showSliders?: boolean;
  showAggregate?: boolean;
  inheritFromClient?: boolean;
}

export type CriterionOption = {
  label: string;
  value: string;
};

const FlagsSelector = (props: IFlagsSelectorProps) => {
  const {
    flags,
    namePrefix,
    flagType,
    addFlag,
    classificationType,
    showSliders = true,
    showAggregate = true,
    inheritFromClient: IRinheritFromClient,
  } = props;
  const [criterionOptions, setCriterionOptions] = React.useState<CriterionOption[] | null>(null);
  const [inheritFromClient, setInheritFromClient] = React.useState<boolean>(IRinheritFromClient);

  const mappedCriterionOptions = (options: SecurityClassification[]) => {
    return options.map(({ name, id }) => ({ label: name, value: id }));
  };

  const getCriterionOptions = async () => {
    const options = await SecurityClassification.allByType({ uiSelectable: true, type: classificationType });
    setCriterionOptions(mappedCriterionOptions(options[classificationType]));
  };

  React.useEffect(() => {
    getCriterionOptions();
  }, []);

  const colourGenerator = useDefaultTheme('all');
  const flagsOpacity = () => (inheritFromClient ? '0.5' : '1');

  if (!criterionOptions) return <Loading />;

  return (
    <>
      <Frow justifyContent="between" className="mar-b-2">
        {namePrefix === 'portfolio[portfolio_mandate_attributes][compliance_attributes][flags_attributes]' && (
          <div className="pretty p-icon">
            <Checkbox label="Inherit from client" onToggle={setInheritFromClient} checked={inheritFromClient} />
            <input
              type="hidden"
              name={`portfolio[portfolio_mandate_attributes][compliance_attributes][${flagType}_inherit_from_client]`}
              value={String(inheritFromClient)}
            />
          </div>
        )}
        <button className="button button--secondary button--compact" type="button" onClick={addFlag}>
          Add New Flag
        </button>
      </Frow>
      <div style={{ opacity: flagsOpacity() }}>
        {flags.map((flag, index: number) => (
          <FlagSelector
            key={index}
            index={index}
            flag={flag}
            criterionOptions={criterionOptions}
            backgroundColorGenerator={colourGenerator}
            namePrefix={namePrefix}
            flagType={flagType}
            showSlider={showSliders}
            showAggregate={showAggregate}
            inheritFromClient={false}
          />
        ))}
      </div>
    </>
  );
};

export default FlagsSelector;
