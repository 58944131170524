import * as React from 'react';
import { Cell } from 'react-data-grid';

export default function columns(tierCategories: string[], tierTypes: string[]) {
  return [
    {
      Header: 'Name',
      accessor: 'name',
      columnName: 'Name',
      id: 'name',
    },
    {
      Header: 'Reference',
      accessor: 'reference',
      columnName: 'reference',
      id: 'reference',
    },
    {
      Header: 'User Count',
      accessor: 'userCount',
      columnName: 'User Count',
      id: 'user_count',
    },
    {
      Header: 'Portfolio Count',
      accessor: 'portfolioCount',
      columnName: 'Portfolio Count',
      id: 'portfolio_count',
    },
    {
      Cell: (row) => {
        return (
          <div className="frow frow--items-center">
            <img alt="Client type icon" src={row.original.clientTypeIcon} className="mar-r-1 client-type-table-icon" />
          </div>
        );
      },
      Header: 'Client Type',
      accessor: 'clientType',
      columnName: 'Client Type',
      id: 'client_type',
      sortable: false,
    },
    {
      Header: 'Tier Type',
      accessor: 'tierType',
      columnName: 'Tier Type',
      id: 'tier_type',
      className: 'text-capitalize',
      sortable: true,
      filterOptions: {
        key: 'tier_type',
        options: tierTypes.map((type) => ({ display: type, value: type })),
      },
    },
    {
      Header: 'Tier Category',
      accessor: 'tierCategory',
      columnName: 'Tier Category',
      id: 'tier_category',
      className: 'text-capitalize',
      sortable: true,
      filterOptions: {
        key: 'tier_category',
        options: tierCategories.map((type) => ({ display: type, value: type })),
      },
    },
    {
      Cell: (row) => {
        return row.original.active ? 'Active' : 'Inactive';
      },
      Header: 'Active',
      accessor: 'active',
      columnName: 'Active',
      id: 'active',
      sortable: true,
      filterOptions: {
        key: 'active',
        options: [
          { display: 'Active', value: 'true' },
          { display: 'Inactive', value: 'false' },
        ],
      },
      show: false,
    },
    {
      Cell: (row) => {
        return (
          <a href={row.original.showPath} style={{ display: 'block' }}>
            <div className="rt-tr-clickable-icon">
              <i className="icon-arrow-right-top icon-0-8x" />
            </div>
          </a>
        );
      },
      Header: '',
      sortable: false,
    },
  ];
}
