import { Attr, BelongsTo, HasMany, HasOne, Model } from 'spraypaint';

import { ApplicationRecord } from './ApplicationRecord';
import { AdditionalAttribute } from './AdditionalAttribute';
import { Client } from './Client';
import { User } from './User';
import { Manager } from './Manager';
import { Entity } from './Entity';
import { Note, Review } from './Models';
import { ManagerUser } from './ManagerUser';
import { Mandate } from './Mandate';
import { ExtraSetting } from './ExtraSetting';
import { Holding } from './Holding';
import { Balance } from './Balance';
import { CashFlow } from './CashFlow';
import { Administrator } from './Administrator';
import { Bookkeeping } from './Bookkeeping';
import { PortfolioDocument } from '.';

@Model()
export class Portfolio extends ApplicationRecord {
  public static jsonapiType = 'portfolios';

  @Attr({ persist: false }) public alertOnUpdate;
  @Attr() public activeFee: number;
  @Attr() public additionalAttributesHash: { [key: string]: any };
  @Attr() public additionalReference: string;
  @Attr() public allocationCommentary: boolean;
  @Attr() public answers: boolean;
  @Attr() public attachments: any;
  @Attr() public bookkeepingEnabled: boolean;
  @Attr() public clientId: string;
  @Attr() public clientShowPath: string;
  @Attr() public clientType: string;
  @Attr() public clientTypeIcon: string;
  @Attr() public contactUsers: any;
  @Attr() public combined: boolean;
  @Attr() public consolidated: boolean;
  @Attr() public currency: string;
  @Attr() public assetsUnderManagement: number;
  @Attr() public dataFrequencyMonths: string;
  @Attr() public dataChaseType: string;
  @Attr() public entityShowPath: string;
  @Attr() public fee: number;
  @Attr() public feeType: string;
  @Attr() public firstReviewDate: string;
  @Attr() public holdingCommentary: boolean;
  @Attr() public investmentManagerReference: string;
  @Attr() public liveStatus: string;
  @Attr() public managerShowPath: string;
  @Attr() public monitoringInvoiceableReviewCount: number;
  @Attr() public monitoringInvoiceableTotalReviewFee: number;
  @Attr() public performanceCommentary: boolean;
  @Attr() public primaryContactId: string;
  @Attr() public primaryContactType: string;
  @Attr() public quantOnly: boolean;
  @Attr() public q1: boolean;
  @Attr() public q2: boolean;
  @Attr() public q3: boolean;
  @Attr() public q4: boolean;
  @Attr() public name: string;
  @Attr() public portfolioRelationshipManagerId: string;
  @Attr() public ratingCommentary: boolean;
  @Attr() public reference: string;
  @Attr() public reportTemplateIds: string[];
  @Attr() public reviewCommentaryType: string;
  @Attr() public reviewFrequency: string;
  @Attr() public reviewPeriodYears: string;
  @Attr() public risk: string;
  @Attr() public secondaryContactId: string;
  @Attr() public secondaryContactType: string;
  @Attr() public status: string;
  @Attr() public serviceType: string;
  @Attr() public showPath: string;
  @Attr() public slaDays: number;
  @Attr() public updatedAt: string;
  @Attr() public useLookthroughAssetClass: boolean;
  @Attr() public adminSystem: string;
  @Attr() public adminSystemReference: string;
  @Attr() public custodian: string;
  @Attr() public custodianReference: string;
  @Attr() public jobstreamPortfolioNumber: string;
  @Attr() public tertiaryContactId: string;
  @Attr() public tertiaryContactType: string;
  @Attr({ persist: false }) public enrichedHolding: any;
  @Attr({ persist: false }) public enrichedHoldingCurrency: any;

  @Attr() public benchmarkInformation: {
    id: number;
    primary: boolean;
    secondary: boolean;
    tertiary: boolean;
    benchmark_id: number;
    benchmark_name: string;
    benchmark_type: string;
  }[];
  @Attr() public linkedBenchmarks: {
    benchmark_type: string;
    id: string;
    name: string;
    primary: boolean;
    secondary: boolean;
  }[];
  @Attr() public managerUsersIds: string[];
  @Attr() public tabs: { name: string; active: boolean; complete: boolean }[];

  @Attr() public mandatePath: string;
  @Attr() public ipsPath: string;
  @Attr() public applicationFormPath: string;
  @Attr() public riskProfilerPath: string;

  @BelongsTo() public client: Client;
  @BelongsTo() public entity: Entity;
  @BelongsTo() public manager: Manager;
  @BelongsTo() public managerUser: ManagerUser;
  @BelongsTo() public relationshipManager: Administrator;
  @BelongsTo() public portfolioRelationshipManager: Administrator;

  @HasMany() public reviews: Review[];
  @HasMany() public extraSettings: ExtraSetting;
  @HasMany() public holdings: Holding[];
  @HasMany() public balances: Balance[];
  @HasMany() public cashFlows: CashFlow[];
  @HasMany() public childPortfolios: Portfolio[];
  @HasMany() public notes: Note[];
  @HasMany() public additionalAttributes: AdditionalAttribute[];
  @HasMany(User) public emapUsers: User[];
  @HasMany(PortfolioDocument) public documents: PortfolioDocument[];
  @HasMany() public bookkeeping: Bookkeeping[];

  @HasOne() public portfolioMandate: Mandate;
  @HasOne(User) public primaryContact: User;
  public tempEmapUsers: User[];

  public tempPrimaryContacts: User[];
}
