import React, { useState, useEffect } from 'react';
import StrategicAllocation from './StrategicAllocation';
import Loading from 'components/shared/Loading';
import TacticalAllocations from './TacticalAllocations';
import { Compliance, Mandate } from 'javascript/models';
import { InvestmentRestrictionsProvider } from './context';
import SliderFlag from './Flags/SliderFlag';
import Flags from './Flags';
import CheckboxFlag from './Flags/CheckboxFlag';
import Checkbox from '../Checkbox';
import { Frow } from 'components/frow';
import RadioFlag from './Flags/RadioFlag';

type Props = {
  compliance?: Compliance;
  mandate?: Mandate;
  baseParam: string;
};

const InvestmentRestrictions = (props: Props) => {
  const { baseParam, mandate } = props;
  const complianceParam = `${baseParam}[compliance_attributes]`;
  const flagParam = `${complianceParam}[flags_attributes]`;

  const [compliance, setCompliance] = useState<Compliance | null>(null);
  const lastEdit = compliance ? compliance.lastEditText : '';

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.compliance) {
      setCompliance(new Compliance(props.compliance));
    } else {
      setCompliance(
        new Compliance({
          ipsImaHeld: false,
          foreignCurrencyExposure: 0,
          singleStockConcentration: 0,
          singleBondConcentration: 0,
          singleFundConcentration: 0,
          nonInvestmentGradeBonds: 0,
        }),
      );
    }

    setLoading(false);
  }, []);

  if (loading || !compliance) return <Loading />;

  return (
    <InvestmentRestrictionsProvider
      compliance={compliance}
      cashAndFixedIncomeLocked={compliance.cashAndFixedIncomeLocked}
    >
      <Frow className="pad-v-2">
        <div className="col-md-3-4">
          <h4 className="form__fieldset-label">Investment Restrictions Settings</h4>
          <span>{lastEdit}</span>
        </div>
        <Checkbox
          checked={compliance.useDefault}
          label="Use defaults"
          param={`${complianceParam}[use_default]`}
          classNames="col-md-1-4"
        />
      </Frow>

      <CheckboxFlag namePrefix={flagParam} flagType="ips_ima_held" label="IPS/IMA Held" />

      {mandate && (
        <>
          <input type="hidden" name="portfolio[portfolio_mandate_attributes][id]" value={mandate.id} />

          <fieldset className="form__fieldset pad-t-2">
            <StrategicAllocation namePrefix={flagParam} mandate={mandate} iRPrefix={complianceParam} />
          </fieldset>

          <fieldset className="form__fieldset pad-v-2">
            <TacticalAllocations namePrefix={flagParam} mandate={mandate} iRPrefix={complianceParam} />
          </fieldset>
        </>
      )}

      <fieldset className="form__fieldset pad-v-2">
        <SliderFlag namePrefix={flagParam} flagType="foreign_currency_exposure" label="Foreign Currency Exposure" />

        <SliderFlag namePrefix={flagParam} flagType="single_stock_concentration" label="Single Stock Concentration" />

        <SliderFlag
          namePrefix={flagParam}
          flagType="single_direct_bond_concentration"
          label="Single Direct Bond Concentration"
        />

        <SliderFlag
          namePrefix={flagParam}
          flagType="single_fund_concentration"
          label="Single Fund Concentration Risk"
        />

        <SliderFlag namePrefix={flagParam} flagType="non_investment_grade_bonds" label="Non Investment Grade Bonds" />

        <SliderFlag namePrefix={flagParam} flagType="other_concentration" label="Other Concentrations" />
      </fieldset>

      <Flags
        flagType="country_of_domicile"
        classificationType="Securities::Classifications::Country"
        title="Country of Domicile"
        baseParam={complianceParam}
        showSliders={false}
        showAggregate={false}
      />

      <CheckboxFlag namePrefix={flagParam} flagType="consecutive_ratings" label="Consecutive Ratings" />

      <CheckboxFlag namePrefix={flagParam} flagType="prior_action_point_status" label="Prior Action Point Status" />

      <Flags
        flagType="currency"
        classificationType="Securities::Classifications::Currency"
        title="Currency"
        baseParam={complianceParam}
      />

      <Flags
        flagType="instrument"
        classificationType="Securities::Classifications::Instrument"
        title="Instrument"
        baseParam={complianceParam}
      />

      <CheckboxFlag
        namePrefix={flagParam}
        flagType="dividend_distribution_policy"
        label="Dividend Distribution Policy"
      />

      <RadioFlag
        namePrefix={flagParam}
        flagType="income_and_capital_segregation"
        label="Income and capital segregation"
        options={[
          { value: '1', label: 'First Quarter' },
          { value: '2', label: 'Second Quarter' },
          { value: '3', label: 'Third Quarter' },
          { value: '4', label: 'Fourth Quarter' },
        ]}
      />

      <RadioFlag
        namePrefix={flagParam}
        flagType="fund_report_status"
        label="Fund reporting status"
        options={[
          { value: '1', label: 'First Quarter' },
          { value: '2', label: 'Second Quarter' },
          { value: '3', label: 'Third Quarter' },
          { value: '4', label: 'Fourth Quarter' },
        ]}
      />

      <Flags
        flagType="industry"
        classificationType="Securities::Classifications::Industry"
        title="Industry"
        baseParam={complianceParam}
      />

      <Flags
        flagType="country_of_risk"
        classificationType="Securities::Classifications::Country"
        title="Country of Risk"
        baseParam={complianceParam}
      />

      <fieldset className="form__fieldset pad-t-2">
        <SliderFlag
          namePrefix={flagParam}
          flagType="max_drawdown"
          label="Max Drawdown"
          reverse={true}
          negative={true}
        />

        <SliderFlag namePrefix={flagParam} flagType="relative_performance" label="Relative Performance" />
      </fieldset>
    </InvestmentRestrictionsProvider>
  );
};

export default InvestmentRestrictions;
