import React, { useContext, useEffect, useRef } from 'react';
import { uniqueId } from 'lodash';

import useFlag from './useFlag';
import RangeSlider from 'components/shared/forms/RangeSlider';
import FormRow from 'components/shared/forms/FormRow';
import { InvestmentRestrictionsContext } from '../context';

type Props = {
  flagType: string;
  backgroundColor: string;
  namePrefix: string;
  label: string;
};

function RangeSliderFlag({ flagType, backgroundColor, namePrefix, label }: Props) {
  const { cashAndFixedIncomeLocked, compliance } = useContext(InvestmentRestrictionsContext);
  const [flag, setFlag] = useFlag(compliance, flagType);

  useEffect(() => {
    if (flag && ['cash_tactical', 'fixed_income_tactical'].includes(flagType)) {
      setFlag({ ...flag, minimum: 0, maximum: 100 });
    }
  }, [cashAndFixedIncomeLocked]);

  function handleSlideEnd(values) {
    setFlag({ ...flag, minimum: values[0], maximum: values[1] });
  }

  if (!flag) return null;

  const { minimum, maximum, id, useDefaults } = flag;
  const name = `${namePrefix}[${uniqueId()}]`;
  const defaultValue = [minimum, maximum];
  const pushable = 1;

  return (
    <FormRow>
      <label htmlFor="client_type" className="form__label">
        {label}
      </label>
      <div className="mar-b-3">
        <RangeSlider {...{ backgroundColor, defaultValue, handleSlideEnd, pushable }} showValues />
      </div>
      <input type="hidden" name={`${name}[minimum]`} value={minimum} />
      <input type="hidden" name={`${name}[maximum]`} value={maximum} />
      <input type="hidden" name={`${name}[id]`} value={id} />
      <input type="hidden" name={`${name}[flag_type]`} value={flagType} />
      <input type="hidden" name={`${name}[use_defaults]`} value={useDefaults ? 1 : 0} />
    </FormRow>
  );
}

export default RangeSliderFlag;
