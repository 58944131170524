import React from 'react';
import { Alert, Intent } from '@blueprintjs/core';
import useVisibility from 'components/shared/customHooks/useVisibility';
import { ApiKey } from 'javascript/models';

const ActionButton = ({ actionType = 'delete', apiKeyResource, disabled = false, handleTableUpdates }) => {
  const { handleClose, handleOpen, isOpen } = useVisibility(false);

  const confirmAction = async () => {
    if (actionType === 'delete') {
      await apiKeyResource.destroy();
    } else if (actionType === 'disable') {
      const updatedNote = new ApiKey({ id: apiKeyResource.id });
      updatedNote.isPersisted = true;
      updatedNote.active = false;
      await updatedNote.save();
    }
    handleClose();
    handleTableUpdates();
  };

  return (
    <>
      <button
        type="button"
        className={`button button--secondary disabled button--${actionType === 'delete' ? 'danger' : ''} button--icon`}
        onClick={handleOpen}
        disabled={disabled}
      >
        <i className={`icon-${actionType === 'delete' ? 'bin' : 'toggle-visibility-invisible'} icon-fw`} />
      </button>

      <Alert
        isOpen={isOpen}
        onClose={handleClose}
        canEscapeKeyCancel={true}
        canOutsideClickCancel={true}
        cancelButtonText="Cancel"
        confirmButtonText={actionType === 'delete' ? 'Delete' : 'Disable'}
        intent={actionType === 'delete' ? Intent.DANGER : Intent.PRIMARY}
        onCancel={handleClose}
        onConfirm={confirmAction}
        portalContainer={document.body}
      >
        <p className="mar-t-0">Are you sure you want to {actionType} this API Key?</p>
      </Alert>
    </>
  );
};

export default ActionButton;
