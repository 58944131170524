import * as React from 'react';

import { Client, User } from 'javascript/models';

import CurrentUserContext from 'components/shared/context/CurrentUserContext';
import useLocalStorage from 'components/shared/customHooks/useLocalStorage';

import Loading from 'components/shared/Loading';

import Presenter from './show/Presenter';

export interface IShowProps {
  client: Client;
  clientTypes: string[];
  currencies: string[];
  riskOptions: string[];
  showActionPoints: boolean;
  showEmapRatings: boolean;
  currentUser: Pick<User, 'id'>;
  selectableQuarters: string[];
  selectableUsers: { label: string; value: string }[];
  contactOptions: { label: string; value: string }[];
}

export default function Show(props: IShowProps) {
  const [currentUser, setCurrentUser] = React.useState<User>();
  const {
    client,
    clientTypes,
    currencies,
    riskOptions,
    showActionPoints,
    showEmapRatings,
    currentUser: currentUserProp,
    selectableQuarters,
    selectableUsers,
    contactOptions,
  } = props;

  const { id: currentUserId } = currentUserProp;

  React.useEffect(() => {
    getUser(currentUserId);
  }, []);

  const getUser = async (UserId) => {
    const { data } = await User.withPermissions.includes({ roles: ['client', 'permissions', 'roleable'] }).find(UserId);
    setCurrentUser(data);
  };

  function defaultLayout() {
    const defaultElements = [
      { name: 'Review Progress', width: 1 },
      { name: 'EMAP Ratings', width: 1 },
      { name: 'Action Points', width: 2 },
      { name: 'Bookkeeping', width: 1 },
      { name: 'Breakdown by Manager', width: 1 },
      { name: 'Risk Profile Split', width: 1 },
      { name: 'Service Type', width: 1 },
      { name: 'Client Type', width: 1 },
      { name: 'AUM', width: 1 },
      { name: 'Portfolios', width: 2 },
    ];

    let xPosition = 0;
    let yPosition = 0;
    const largeLayout = defaultElements.map((element) => {
      xPosition += element.width;
      if (xPosition > 2) {
        xPosition = 1;
        yPosition += 1;
      }
      const layoutObject = { i: element.name, x: xPosition - 1, y: yPosition, h: 1, w: element.width };
      if (element.width === 2) {
        xPosition = 0;
        yPosition += 1;
      }
      return layoutObject;
    });

    const smallLayout = defaultElements.map((element, index) => {
      return { i: element.name, x: 0, y: index, h: 1, w: 1 };
    });

    return { lg: largeLayout, sm: smallLayout };
  }

  const defaultDisplay = {
    AUM: true,
    'Action Points': true,
    Bookkeeping: false,
    'Breakdown by Manager': true,
    'Service Type': true,
    'Client Type': true,
    'EMAP Ratings': true,
    Portfolios: true,
    'Review Progress': true,
    'Risk Profile Split': true,
  };

  const { storedValue: layout, setItem } = useLocalStorage('portal:client:dashboard', defaultLayout(), false);

  const { storedValue: layoutDisplay, setItem: setLayoutDisplay } = useLocalStorage(
    'portal:client:dashboardDisplay',
    defaultDisplay,
    false,
  );

  if (!currentUser) return <Loading />;

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <Presenter
        {...{
          client,
          clientTypes,
          currencies,
          layout,
          layoutDisplay,
          riskOptions,
          setItem,
          setLayoutDisplay,
          showActionPoints,
          showEmapRatings,
          selectableQuarters,
          selectableUsers,
          contactOptions,
        }}
      />
    </CurrentUserContext.Provider>
  );
}
