import React, { useEffect, useState } from 'react';
import GraphitiTableWrapper from 'components/shared/GraphitiTableWrapper';
import { ApiKey } from 'javascript/models';
import columns from './table/columns';

const GraphitiTable = () => {
  const [updateTable, setUpdateTable] = useState(false);

  useEffect(() => {
    if (updateTable) {
      setUpdateTable(false);
    }
  }, [updateTable]);

  useEffect(() => {
    const handleApiKeyCreated = (_event) => {
      setUpdateTable(true);
    };

    document.addEventListener('apiKey:new', handleApiKeyCreated);

    return () => {
      document.removeEventListener('apiKey:new', handleApiKeyCreated);
    };
  }, []);

  const handleTableUpdates = () => {
    setUpdateTable(true);
  };
  const scope = ApiKey.order({ created_at: 'desc' });

  return (
    <>
      <GraphitiTableWrapper
        scope={scope}
        columns={columns(handleTableUpdates)}
        shouldUpdateTable={updateTable}
        identifier="apiKeys:index"
      />
    </>
  );
};

export default GraphitiTable;
