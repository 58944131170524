import { Attr, BelongsTo, HasMany, HasOne, Model } from 'spraypaint';

import { ApplicationRecord } from '../ApplicationRecord';
import { Entity } from '../Entity';
import { Manager } from '../Manager';
import { Review } from './Review';
import { Mandate } from '../Mandate';
import { User } from '../User';

@Model()
export class Portfolio extends ApplicationRecord {
  public static jsonapiType = 'portal/portfolios';
  @Attr() public clientType: string;
  @Attr() public currency: string;
  @Attr() public reference: string;
  @Attr() public risk: string;
  @Attr() public status: string;
  @Attr() public name: string;
  @Attr() public adminSystemReference: string;
  @Attr() public bookkeepingEnabled: boolean;

  // Extra Attributes
  @Attr() public assetMovementTableData: { data: any };
  // Its a string because its formatted to be a value with a currency symbol and commas
  @Attr() public balanceAtSelectedDate: string;
  // Should probably be a boolean, but there is actually three options, yes no and no review
  @Attr() public clientTypeIcon: string;
  @Attr() public enrichedData: string;
  @Attr() public historicRatings: { date: string; value: string }[];
  @Attr() public rating: string;
  @Attr() public outstandingActionPoints: any;
  @Attr() public portfolioType: string;
  @Attr() public valueSeriesChartData: { data: any; ticks: number[] };
  @Attr() public reviewStatusAtSelectedDate: string;
  @Attr() public dataStatus: string;

  @BelongsTo() public entity: Entity;
  @BelongsTo() public manager: Manager;
  @BelongsTo(User) public primaryContact: User;
  @BelongsTo(User) public secondaryContact: User;
  @BelongsTo(User) public tertiaryContact: User;
  @HasOne() public mostRecentReview: Review;
  @HasMany() public reviews: Review[];
  @HasOne(Mandate) public portfolioMandate: Mandate;
}
