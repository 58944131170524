import React, { createContext, useState } from 'react';
import { Compliance, Mandate } from 'javascript/models';

interface InvestmentRestrictionsContextInterface {
  compliance: Compliance | null;
  setCompliance: (value: any) => void;
  cashAndFixedIncomeLocked: boolean;
  setCashAndFixedIncomeLocked: (value: boolean) => void;
}

type Props = {
  children: React.ReactNode;
  compliance: Compliance;
  cashAndFixedIncomeLocked?: boolean;
};

export const InvestmentRestrictionsContext = createContext<InvestmentRestrictionsContextInterface>({
  compliance: null,
  setCompliance: () => {},
  cashAndFixedIncomeLocked: false,
  setCashAndFixedIncomeLocked: () => {},
});

export const InvestmentRestrictionsProvider = (props: Props) => {
  const [compliance, setCompliance] = useState<Compliance>(props.compliance);
  const [cashAndFixedIncomeLocked, setCashAndFixedIncomeLocked] = useState<boolean>(props.cashAndFixedIncomeLocked);

  return (
    <InvestmentRestrictionsContext.Provider
      value={{
        compliance,
        setCompliance,
        cashAndFixedIncomeLocked,
        setCashAndFixedIncomeLocked,
      }}
    >
      {props.children}
    </InvestmentRestrictionsContext.Provider>
  );
};
