import { Attr, BelongsTo, Model } from 'spraypaint';
import { ApplicationRecord } from './ApplicationRecord';
import { User } from './User';
import { Administrator } from './Administrator';

@Model()
export class ApiKey extends ApplicationRecord {
  public static jsonapiType = 'api_keys';
  @Attr() public apiKey: string;
  @Attr() public active: boolean;
  @Attr() public label: string;
  @Attr() public createdAt: Date;
  @Attr() public updatedAt: Date;
  @BelongsTo() public bearer: User | Administrator;
}
