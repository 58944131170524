import React, { useEffect } from 'react';

import ReactPieChart from '../../../shared/charting/recharts/ReactPieChart';
import Loading from '../../../shared/Loading';
import { Portfolio } from 'javascript/models/portal/Portfolio';
import ClientPortalCard from '../../shared/ClientPortalCard';
import IPieChartData from '../../../shared/charting/recharts/interfaces/IPieChartData';
import PieChartTableLegend from './PieChartTableLegend';
import { useClientPortalTheme } from 'components/shared/colours/themes/clientPortal';
import useChartFilter from 'components/shared/customHooks/useChartFilter';
import stateToggle from './shared/stateToggle';

import { Frow, Column } from 'components/frow';

const Bookkeeping = ({ clientId, filterState }) => {
  const [chartData, setChartData] = React.useState<IPieChartData[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [stats, setStats] = React.useState({});
  const [selectedSegment, setSelectedSegment] = filterState;

  const getPortfolios = async () => {
    const scope = Portfolio.per(0)
      .where({ status: 'live', client_id: clientId })
      .stats({
        count: ['enabled_bookkeeping', 'potential_bookkeeping', 'disabled_bookkeeping'],
      });

    const { count } = (await scope.all()).meta.stats;
    setStats(count);
  };

  const statusTitle = (status) => {
    switch (status) {
      case 'enabled_bookkeeping':
        return 'Enabled';
      case 'potential_bookkeeping':
        return 'Potential';
      case 'disabled_bookkeeping':
        return 'Not Applicable';
    }
  };

  const formattedData = () => {
    const colourGen = useClientPortalTheme('reviewBreakdown');

    const data: IPieChartData[] = Object.keys(stats).map((stat) => ({
      name: statusTitle(stat),
      value: stats[stat],
      opacity: '1',
      color: colourGen(stat),
    }));

    return data;
  };

  const titleToStatus = (title) => {
    switch (title) {
      case 'Enabled':
        return 'bookkeeping_enabled';
      case 'Potential':
        return 'bookkeeping_potential';
      case 'Not Applicable':
        return ['no_data', 'na'];
    }
  };

  const handleSelect = React.useCallback(
    (slice) => {
      stateToggle(setSelectedSegment, false)(titleToStatus(slice));
    },
    [selectedSegment],
  );

  useChartFilter(chartData, setChartData, 'name', selectedSegment);

  useEffect(() => {
    setIsLoading(true);
    getPortfolios().then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setChartData(formattedData());
  }, [stats]);

  const renderData = () => {
    const total = chartData.reduce((acc, { value }) => acc + value, 0);

    return (
      <Frow columnContext={{ columnSpan: 1, maxColumns: 2 }}>
        <Column>
          <ReactPieChart
            activeShape
            animationBegin={50}
            animationDuration={600}
            data={chartData}
            height={320}
            labelText={total}
            noData={total === 0}
            displayLegend={false}
            setSelectedSlice={handleSelect}
          />
        </Column>
        <PieChartTableLegend
          headerTitle="Bookkeeping Status"
          {...{ data: chartData }}
          description="Breakdown of portfolio bookkeeping status."
          isPercentage={false}
        />
      </Frow>
    );
  };

  return (
    <ClientPortalCard title="Bookkeeping">
      {isLoading || !chartData.length ? <Loading /> : renderData()}
    </ClientPortalCard>
  );
};

export default Bookkeeping;
