import * as React from 'react';

import { endOfQuarter, subQuarters } from 'date-fns';

import { User } from 'javascript/models';

import ActionPoints from 'components/portal/shared/ActionPoints';
import CurrentUserContext from 'components/shared/context/CurrentUserContext';

import { IShowProps } from '../Show';
import AssetsUnderManagement from './AssetsUnderManagement';
import ClientTypeSplit from './clientServiceType/ClientTypeSplit';
import ServiceType from './clientServiceType/ServiceType';
import Header from './Header';
import ImBreakdown from './ImBreakdown';
import Portfolios from './Portfolios';
import RatingBreakdown from './RatingBreakdown';
import ReviewBreakdown from './ReviewBreakdown';
import RiskBreakdown from './RiskBreakdown';
import Bookkeeping from './Bookkeeping';

export interface IPresenterProps {
  client: IShowProps['client'];
  clientTypes: string[];
  currencies: string[];
  riskOptions: string[];
  showEmapRatings: boolean;
  layout: any;
  layoutDisplay: any;
  setItem: any;
  setLayoutDisplay: any;
  selectableQuarters: string[];
  selectableUsers: { label: string; value: string }[];
  contactOptions: { label: string; value: string }[];
}

const presenter = (props: IPresenterProps) => {
  const {
    client,
    clientTypes,
    currencies,
    riskOptions,
    showEmapRatings,
    layout,
    layoutDisplay,
    setLayoutDisplay,
    setItem,
    selectableQuarters,
    selectableUsers,
    contactOptions,
  } = props;
  const [selectedDate, setSelectedDate] = React.useState(endOfQuarter(subQuarters(new Date(), 1)));
  const localFilterState = React.useState({});
  const [localFilters, setLocalFilters] = localFilterState;

  const { id: clientId } = client;

  const currentUser = React.useContext(CurrentUserContext) as User;

  const setFilter = (filterKey: string) => (newValue) =>
    setLocalFilters((previous) => ({
      ...previous,
      [filterKey]: typeof newValue == 'function' ? newValue(previous[filterKey]) : newValue,
    }));
  const filterState = (filterKey: string) => [localFilters[filterKey], setFilter(filterKey)];

  const order = (componentTitle) => {
    const layoutComponent = layout.lg.find((item) => item.i === componentTitle);
    const { x, y } = layoutComponent;
    const orderNumber = y * 2 + x + 1;
    return `col-order-${orderNumber + 1}`;
  };

  return (
    <React.Fragment>
      <Header
        {...{
          client,
          clientId,
          selectedDate,
          setSelectedDate,
          layout,
          setItem,
          layoutDisplay,
          setLayoutDisplay,
          selectableQuarters,
          currencies,
          selectableUsers,
          contactOptions,
        }}
      />
      <div className="platform-panel platform-panel--no-background">
        <div className="frow frow--gutters">
          {layoutDisplay['Review Progress'] && (
            <div
              className={
                showEmapRatings
                  ? `col-sm-1-1 col-md-1-2 ${order('Review Progress')}`
                  : `col-sm-1-1 ${order('Review Progress')}`
              }
            >
              <ReviewBreakdown {...{ clientId, selectedDate, filterStateFunc: filterState }} />
            </div>
          )}
          {layoutDisplay['EMAP Ratings'] && showEmapRatings && (
            <div className={`col-sm-1-1 col-md-1-2 ${order('EMAP Ratings')}`}>
              <RatingBreakdown {...{ clientId, selectedDate, filterState: filterState('rating') }} />
            </div>
          )}
          {layoutDisplay['Action Points'] && (
            <div className={`col-md-1-1 ${order('Action Points')}`}>
              <ActionPoints {...{ client, selectedDate, currentUser, titleControlClass: 'col-md-1-2' }} />
            </div>
          )}
          {layoutDisplay['Bookkeeping'] && (
            <div className={`col-sm-1-1 col-md-1-2 ${order('Bookkeeping')}`}>
              <Bookkeeping {...{ clientId, filterState: filterState('data_status') }} />
            </div>
          )}
          {layoutDisplay['Breakdown by Manager'] && (
            <div className={`col-sm-1-1 col-md-1-2 ${order('Breakdown by Manager')}`}>
              <ImBreakdown {...{ clientId, selectedDate, filterState: filterState('manager_id') }} />
            </div>
          )}
          {layoutDisplay['Risk Profile Split'] && (
            <div className={`col-sm-1-1 col-md-1-2 ${order('Risk Profile Split')}`}>
              <RiskBreakdown {...{ clientId, selectedDate, filterState: filterState('risk') }} />
            </div>
          )}
          {layoutDisplay['Service Type'] && (
            <div className={`col-sm-1-1 col-md-1-2 ${order('Service Type')}`}>
              <ServiceType {...{ clientId, selectedDate, filterState }} />
            </div>
          )}
          {layoutDisplay['Client Type'] && (
            <div className={`col-sm-1-1 col-md-1-2 ${order('Client Type')}`}>
              <ClientTypeSplit {...{ clientId, selectedDate, filterState }} />
            </div>
          )}
          {layoutDisplay.AUM && (
            <div className={`col-sm-1-1 col-md-1-2 ${order('AUM')}`}>
              <AssetsUnderManagement {...{ clientId, selectedDate }} />
            </div>
          )}
          {layoutDisplay.Portfolios && (
            <div className={`col-md-1-1 ${order('Portfolios')}`}>
              <Portfolios
                {...{
                  clientId,
                  clientTypes,
                  currencies,
                  filterState: localFilterState,
                  riskOptions,
                  selectedDate,
                }}
              />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default presenter;
