import React, { useContext, useMemo } from 'react';
import Checkbox from 'components/shared/Checkbox';
import { uniqueId } from 'lodash';
import { Flag } from 'javascript/models';
import { InvestmentRestrictionsContext } from '../context';
import { Frow } from 'components/frow';
import ConfigurationSettings from './ConfigurationSettings';

type Props = {
  flagType: string;
  namePrefix: string;
  label: string;
};

const CheckboxFlag = (props: Props) => {
  const { flagType, namePrefix, label } = props;

  const { compliance } = useContext(InvestmentRestrictionsContext);
  const { flags } = compliance;
  const existingFlag = useMemo(() => flags.filter((flag) => flag.flagType === flagType), [flags, flagType]);
  const flag = existingFlag[0] || new Flag({ flagType, generateActionPoint: true });

  const name = `${namePrefix}[${uniqueId()}]`;

  return (
    <fieldset className="form__fieldset pad-v-3">
      <h4 className="form__fieldset-label">{label}</h4>

      <Frow>
        <div className="col-md-3-5">
          <ConfigurationSettings name={name} flag={flag} />
        </div>
      </Frow>

      <input type="hidden" name={`${name}[id]`} value={flag.id} />
      <input type="hidden" name={`${name}[flag_type]`} value={flagType} />
    </fieldset>
  );
};

export default CheckboxFlag;
