import * as React from 'react';

import { endOfQuarter, format, parse, subQuarters } from 'date-fns';

import { User } from 'javascript/models';
import * as ReactTooltip from 'react-tooltip';

import { portalDownloadsPath } from 'javascript/application/ts_routes';

import ajaxRequest from 'components/shared/utilities/ajaxRequest';
import QuarterPicker from 'components/shared/forms/QuarterPicker';
import CustomModal from 'components/shared/CustomModal';
import useVisibility from 'components/shared/customHooks/useVisibility';
import CustomAlert from 'components/shared/Alert';
import { createAlert } from 'components/shared/Utils';

import CurrentUserContext from 'components/shared/context/CurrentUserContext';

import Button from 'components/shared/forms/Button';
import { HeadingOne, SubtitleTwo } from 'components/shared/Typography';

import Reorder from './header/Reorder';
import Settings from './header/Settings';

import MirDownload from '../MirDownload/MirDownload';

export default function header({
  client,
  selectedDate,
  setSelectedDate,
  layout,
  setItem,
  layoutDisplay,
  setLayoutDisplay,
  currencies,
  selectableQuarters,
  selectableUsers,
  contactOptions,
}) {
  const { isOpen, handleClose, handleOpen } = useVisibility(false);
  const {
    isOpen: isReorderOpen,
    handleClose: handleReorderClose,
    handleOpen: handleReorderOpen,
  } = useVisibility(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const currentUser = React.useContext(CurrentUserContext) as User;

  const currencySelectList = currencies.map((currency) => {
    return { label: currency.toUpperCase(), value: currency };
  });

  return (
    <div className="platform-panel platform-panel--no-background">
      <div className="frow frow--items-start frow--gutters frow--justify-between frow--centered-column frow--row-lg">
        <div className="frow frow--items-center">
          <div>
            <SubtitleTwo text={format(new Date(), 'dddd[,] DD MMMM YYYY')} />
            <HeadingOne text={client.name} additionalClasses="text-lh-1" />
          </div>
        </div>
        <div className="frow frow--items-end frow--gutters">
          <div>
            <SubtitleTwo text="Time Period" />
            <div style={{ width: '150px' }}>
              <QuarterPicker
                defaultValue={format(parse(selectedDate), 'YYYY-MM-DD')}
                handleChange={setSelectedDate}
                id="tracker_date"
                name="tracker_date"
                reverseDates
                startDate={format(endOfQuarter(subQuarters(new Date(), 17)), 'YYYY-MM-DD')}
                theme="dark"
                showQuarterIndicator
              />
            </div>
          </div>
          <div>
            <button
              style={{ marginBottom: '2.5px' }}
              className="button button--secondary button--full-width button--no-min-width"
              onClick={handleOpen}
              disabled={!currentUser.hasPermission('mir_creator', client.id)}
            >
              Create MIR
            </button>
            <CustomModal isOpen={isOpen} title="Create MIR" handleClose={handleClose} modifiers={['dark']}>
              <div className="modal__content">
                <CustomAlert
                  message="You can create a Management Information Report in PDF and/or Excel.
                          Please select from the options below to create your report(s).
                          They will be generated in the background, and you will receive a notification when they are complete."
                  modifier="dark"
                  title="MIR Download"
                  type="info"
                />
                <MirDownload
                  client={client}
                  clientCurrency={client.feeCurrency}
                  currencies={currencySelectList}
                  selectableQuarters={selectableQuarters}
                  selectableUsers={selectableUsers}
                  afterSubmit={handleClose}
                  defaultReviewDate={format(parse(selectedDate), 'YYYY-MM-DD')}
                />
              </div>
            </CustomModal>
          </div>
          <div>
            <button
              style={{ marginBottom: '2.5px' }}
              className="button button--no-min-width button--secondary"
              onClick={handleReorderOpen}
            >
              <i className="fas fa-cog" />
            </button>
          </div>
        </div>
      </div>
      <Settings
        isOpen={isReorderOpen}
        handleClose={handleReorderClose}
        {...{ layout, setItem, layoutDisplay, setLayoutDisplay, clientId: client.id }}
      />
    </div>
  );
}
