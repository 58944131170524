import { camelCase } from 'lodash';

import { Attr, Model, HasMany, HasOne } from 'spraypaint';

import { ApplicationRecord } from './ApplicationRecord';
import { Flag } from './Flag';
import { Holding } from './Holding';
import { Compliance } from './Compliance';

interface IAssetAllocationSelector<Value> {
  prefix?: string;
  suffix?: string;
  defaultValue?: Value;
}

@Model()
export class Mandate extends ApplicationRecord {
  public static jsonapiType = 'mandates';
  @Attr() public portfolioId: string;

  @Attr() public cashMinimum: number;
  @Attr() public cashMaximum: number;
  @Attr() public cashStrategic: number;

  @Attr() public fixedIncomeMinimum: number;
  @Attr() public fixedIncomeMaximum: number;
  @Attr() public fixedIncomeStrategic: number;

  @Attr() public equityMinimum: number;
  @Attr() public equityMaximum: number;
  @Attr() public equityStrategic: number;

  @Attr() public hedgeFundMinimum: number;
  @Attr() public hedgeFundMaximum: number;
  @Attr() public hedgeFundStrategic: number;

  @Attr() public otherMinimum: number;
  @Attr() public otherMaximum: number;
  @Attr() public otherStrategic: number;

  @Attr() public commoditiesMinimum: number;
  @Attr() public commoditiesMaximum: number;
  @Attr() public commoditiesStrategic: number;

  @Attr() public privateMarketsMinimum: number;
  @Attr() public privateMarketsMaximum: number;
  @Attr() public privateMarketsStrategic: number;

  @Attr() public realEstateMinimum: number;
  @Attr() public realEstateMaximum: number;
  @Attr() public realEstateStrategic: number;

  @Attr() public amc: string;
  @Attr() public dateSigned: string;
  @Attr() public docType: string;
  @Attr() public documentSigned: boolean;
  @Attr() public drawdownTolerance: number;
  @Attr() public incomeTarget: number;
  @Attr() public performanceFee: string;
  @Attr() public ter: string;
  @Attr() public ongoingChargesFigure: number;
  @Attr() public investmentObjective: string;
  @Attr() public returnObjective: string;
  @Attr() public timeHorizon: string;
  @Attr() public incomeRequirement: string;
  @Attr() public restrictions: string;
  @Attr() public riskObjective: string;

  @HasMany(Flag) public flags: Flag[];
  @HasOne(Compliance) public compliance: Compliance;

  private assetAllocationsValues<Value>(selectors: IAssetAllocationSelector<Value>[]) {
    const getStrategicAllocations = async () =>
      (await Holding.allAvailableAssetClasses).map(camelCase).map((assetClass: string) => ({
        assetClass,
        values: selectors.map(
          (selector) => this[`${selector.prefix || ''}${assetClass}${selector.suffix || ''}`] || selector.defaultValue,
        ) as Value[],
      }));

    return getStrategicAllocations();
  }

  public get strategicAssetAllocations() {
    return this.assetAllocationsValues([{ suffix: 'Strategic', defaultValue: 0 }]);
  }

  public get tacticalAssetAllocations() {
    return this.assetAllocationsValues([
      { suffix: 'Minimum', defaultValue: 0 },
      { suffix: 'Maximum', defaultValue: 100 },
    ]);
  }
}
