import { useState, useEffect, useMemo } from 'react';
import { Compliance, Flag } from 'javascript/models';

const useFlag = (compliance: Compliance, flagType: string) => {
  const [flag, setFlag] = useState<any>(null);

  const findFlagByType = useMemo(
    () => compliance.flags.filter((flag) => flag.flagType === flagType),
    [compliance, flagType],
  );

  useEffect(() => {
    const existingFlag =
      findFlagByType[0] || new Flag({ flagType, value: 0, minimum: 0, maximum: 100, generateActionPoint: true });
    setFlag(existingFlag);
  }, [findFlagByType]);

  return [flag, setFlag];
};

export default useFlag;
