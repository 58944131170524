import React, { useState, useEffect } from 'react';

import { Compliance } from 'javascript/models';
import InvestmentRestrictions from 'components/shared/InvestmentRestrictions';
import Loading from 'components/shared/Loading';

type Props = {
  complianceId?: string;
};

const Compliances = ({ complianceId }: Props) => {
  const [compliance, setCompliance] = useState<Compliance | null>(null);
  const [loading, setLoading] = useState(true);

  async function getInvestmentRestrictionSetting() {
    if (complianceId) {
      const { data } = await Compliance.includes('flags').find(complianceId);
      setCompliance(data);
    }

    setLoading(false);
  }

  useEffect(() => {
    getInvestmentRestrictionSetting();
  }, []);

  if (!compliance && loading) return <Loading />;

  return <InvestmentRestrictions baseParam="client" compliance={compliance} />;
};

export default Compliances;
